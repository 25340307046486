import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import image1 from "./assets/img/1.png";
import image2 from "./assets/img/2.png";
import image3 from "./assets/img/3.png";
import bg from "./assets/img/bg.png";
const OneArray = [
  {
    id: 1,
    name: "Click on the Payment Link",
    description:
      "Tap on the payments link send via Email, Whatsapp, or through SMS. Oncce you click on the same it will take you to the payments page.",
    image: image1,
    step: "Step 1",
  },
  {
    id: 2,
    name: "Review",
    description:
      "Once the link is clicked you will get it opened in a browser with your transaction details created by us. Verify the details and click on PROCEED TO PAY.",
    image: image2,
    step: "Step 2",
  },
  {
    id: 3,
    name: "Proceed to Pay",
    description:
      "Now you will be taken to the payments page. Enter your debit card details and tap on proceed option to make the payment.",
    image: image3,
    step: "Step 3",
  },
];
const Faster = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={12}>
          <H1
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="500"
          >
            Introducing Payment Links for Our Valuable Customers for Eazy
            Checkout.
          </H1>
        </Col>
        {OneArray.map((item, id) => {
          return (
            <Col>
              <CardCustom>
                <CardBody>
                  {/* <ImgWrapper>
                    <Img className="img-fluid" src={item?.image} alt="" />
                  </ImgWrapper> */}
                  <Card.Title>{item?.name}</Card.Title>
                  <Card.Text>{item?.description}</Card.Text>
                </CardBody>
              </CardCustom>
            </Col>
          );
        })}
      </RowWrapper>
    </Wrapper>
  );
};
export default Faster;

const CardBody = styled(Card.Body)`
  background: #141414;
  border: solid 0.5px #17171e;
  border-radius: 10px;
`;
const CardCustom = styled(Card)`
  border: none;
  height: 100%;
  border-radius: 13px;
`;
const RowWrapper = styled(Row)`
  max-width: 1200px;
  margin: 0 auto !important;
  width: 100%;
`;
const Wrapper = styled.div`
  text-align: center;
  padding: 60px 0;
  background-color: #101010;
  /* background-image: url(${bg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; */
  .card-body {
    padding: 0;
    .card-title {
      color: #9e9e9e;
      font-size: 16px;
      line-height: 28px;
      padding: 25px 0 10px 0;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
    p {
      color: #757575;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      font-weight: 500;
      padding: 0 50px 40px 50px;
    }
  }
`;
const H1 = styled.h1`
  color: #afafaf;
  font-size: clamp(25px, 5vw, 40px);
  line-height: 50px;
  font-weight: 600;
  padding: 0 0 40px 0;
  margin: 0 auto;
  text-align: left;
`;
const ImgWrapper = styled.div``;
const Img = styled.img`
  filter: saturate(0.5);
  padding: 0;
  border-radius: 12px;
`;
