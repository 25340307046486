import React, { useEffect } from "react";
import Banner from "./Banner";
import Empower from "./Empower";
import One from "./One";
import VisionMission from "./VisionMission";
import Steps from "./Steps";
import Faster from "./Faster";
import Menu from "./Menu";
import Remittances from "./Remittances";
import MobileApp from "./MobileApp";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Screens from "./Screens";
const Home = () => {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
    });
  }, []);
  return (
    <div>
      <Menu />
      <Banner />
      <Faster />
      <Empower />
      <One />
      <VisionMission />
      <Steps />
      <Remittances />
      <Screens />
      <Footer />
    </div>
  );
};
export default Home;
