import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "./assets/img/bg.png";
import blur from "./assets/img/blur.png";
import screen1 from "./assets/img/a.png";
import screen2 from "./assets/img/b.png";
const VisionMission = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={6}>
          <ImgWrapper>
            <Img className="img-fluid" src={screen1} alt="" />
          </ImgWrapper>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <H1>Our Vision</H1>
          <H6>
            Purshottam Kanji Exchange, the preferred Money Exchange of residents
            in Sultanate of Oman.
          </H6>
        </Col>
      </RowWrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={6}>
          <ImgWrapper>
            <Img className="img-fluid" src={screen2} alt="" />
          </ImgWrapper>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <H1>Our Mission</H1>
          <H6>
            Create Convenience, Introduce Innovation, Accept Transformation and
            Keep the Trust.
          </H6>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default VisionMission;
const RowWrapper = styled(Row)`
  max-width: 1120px;
  margin: 0 auto !important;
  width: 100%;
  position: sticky;
  top: 0;
  min-height: 100vh;
  align-items: center;
`;
const Wrapper = styled.div`
  background: #101017;
  text-align: center;
  padding: 40% 0 75px 0;
  background-image: url(${bg});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
`;
const H1 = styled.h1`
  font-size: 18px;
  line-height: 28px;
  color: #eeeeee;
  font-weight: 600;
  text-align: left;
  margin: 0;
`;
const H6 = styled.h6`
  margin: 0 auto;
  padding: 10px 0 0 0;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  font-weight: 500;
`;
const ImgWrapper = styled.div`
  background-image: url(${blur});
  background-color: rgb(255 255 255 / 35%);
  padding: 50px 0;
  border-radius: 5px;
`;
const Img = styled.img`
  padding: 15px;
  max-height: 500px;
`;
