import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import mobile from "./assets/img/1.jpg";
import android from "./assets/img/icon/1.png";
import apple from "./assets/img/icon/2.png";
const MobileApp = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <H1>PK Mobile App</H1>
        <Col xs={12} sm={12} md={6}>
          <ImgWrapper>
            <Img className="img-fluid" src={mobile} alt="" />
          </ImgWrapper>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <RowWrapper className="align-content-center h-100">
            <DivBlock>
              <IconBlock>
                <ImgWrapper>
                  <Img className="img-fluid app-icon" src={android} alt="" />
                </ImgWrapper>
              </IconBlock>
              <TitleBlock>
                <H1Colored>Google Play</H1Colored>
                <ALink>Download Now</ALink>
              </TitleBlock>
            </DivBlock>
            <DivBlock>
              <IconBlock>
                <ImgWrapper>
                  <Img className="img-fluid app-icon" src={apple} alt="" />
                </ImgWrapper>
              </IconBlock>
              <TitleBlock>
                <H1Colored>App Store</H1Colored>
                <ALink>Download Now</ALink>
              </TitleBlock>
            </DivBlock>
          </RowWrapper>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default MobileApp;

const TitleBlock = styled.div`
  text-align: left;
`;
const IconBlock = styled.div`
  padding: 0 20px 0 0;
`;
const DivBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 15px 50px;
`;
const ALink = styled.a`
  color: #6d6d6d;
  font-size: 15px;
  font-weight: 500;
`;
const RowWrapper = styled(Row)`
  max-width: 1120px;
  margin: 0 auto !important;
  width: 100%;
`;
const Wrapper = styled.div`
  background: #f9f9f9;
  text-align: center;
  padding: 70px 0 75px 0;
`;
const H1 = styled.h1`
  color: #212121;
  font-size: clamp(25px, 5vw, 45px);
  line-height: 64px;
  font-weight: 700;
  padding: 0 0 50px 0;
`;
const H1Colored = styled.h1`
  font-size: clamp(25px, 5vw, 25px);
  font-weight: 700;
  color: #212121;
  margin: 0;
  background: linear-gradient(123deg, #4bb4b1 16.69%, #e8905e 78.17%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
`;
const H6 = styled.h6`
  color: #454545;
  font-size: clamp(12px, 2vw, 16px);
  margin: 0 auto;
  padding: 10px 0 0 0;
  line-height: 24px;
  text-align: left;
`;

const ImgWrapper = styled.div`
  .app-icon {
    max-height: 45px;
  }
`;
const Img = styled.img`
  padding: 0;
  border-radius: 12px;
`;
