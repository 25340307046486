import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import image1 from "./assets/img/1.jpg";
import image2 from "./assets/img/2.jpg";
import image3 from "./assets/img/3.jpg";
import image4 from "./assets/img/4.jpg";
import aImage from "./assets/img/a.png";
import bImage from "./assets/img/b.png";
import cImage from "./assets/img/c.png";
import dImage from "./assets/img/d.png";
const OneArray = [
  {
    id: 1,
    name: "Remittance",
    description:
      "We provide remittance services such as bank account transfers, business-to-customer (B2C), cash pick up, door delivery as well as demand drafts at the best market rates.",
    image: aImage,
  },
  {
    id: 2,
    name: "Foreign Exchange",
    description:
      "We offer comprehensive foreign exchange services for customers within the OMAN. Individuals can access any of our branches to buy currency at the best market rates.",
    image: bImage,
  },
  {
    id: 3,
    name: "Gold Bars & Coins",
    description:
      "We buys and sells gold bars and coins. We do sell certificates and the actual physical product. We bases pricing on current LIVE market conditions. There are no minimums.",
    image: cImage,
  },
  {
    id: 4,
    name: "Send & Receive Money",
    description:
      "Over the last 30 years, with 100 % commitment and respectful handling of our customers hard earned money, We have earned its customers’ seal of approval.",
    image: dImage,
  },
];
const One = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={12}>
          <H6>Services</H6>
          <H1>One Of The Leading Foreign Exchange Services Provider</H1>
        </Col>
        {OneArray.map((item, id) => {
          return (
            <Col>
              <CardCustom>
                <Card.Body>
                  <ImgWrapper>
                    <Img className="img-fluid" src={item?.image} alt="" />
                  </ImgWrapper>
                  <Card.Title>{item?.name}</Card.Title>
                  <Card.Text>{item?.description}</Card.Text>
                </Card.Body>
              </CardCustom>
            </Col>
          );
        })}
      </RowWrapper>
    </Wrapper>
  );
};
export default One;

const CardCustom = styled(Card)`
  border-radius: 12px;
  height: 100%;
  background-image: linear-gradient(
    to right bottom,
    #12121a,
    #101017,
    #0e0e14,
    #0b0b10,
    #08080c
  );
  border: solid 1px #0f0f14;
`;
const RowWrapper = styled(Row)`
  max-width: 1350px;
  margin: 0 auto !important;
  width: 100%;
`;
const Wrapper = styled.div`
  text-align: center;
  padding: 90px 0;
  background-image: linear-gradient(
    to right top,
    #08080c,
    #08080c,
    #08080c,
    #07080c,
    #07080c
  );
  border-radius: 0;
  .card-body {
    padding: 40px 30px;
    .card-title {
      font-size: 18px;
      line-height: 28px;
      color: #fbfbfb;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #9b9b9b;
      font-weight: 500;
    }
  }
`;
const H1 = styled.h1`
    color: #E0E0E0;
    font-size: clamp(25px, 5vw, 35px);
    line-height: 40px;
    font-weight: 600;
    padding: 0 0 30px 0;
    text-align: left;
    max-width: 750px;
    letter-spacing: -1px;
`;
const H6 = styled.h6`
    color: #f9f9f9;
    font-size: clamp(12px, 2vw, 15px);
    margin: 0 auto;
    padding: 0 0 10px 0;
    line-height: 32px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
`;
const ImgWrapper = styled.div`
  background: #111119;
  border: solid 1px #15151c;
  border-radius: 6px;
  width: 130px;
  height: 130px;
  margin: 0 auto 25px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
`;
const Img = styled.img`
  filter: saturate(0.8);
  padding: 0;
  max-width: 100px;
`;
