import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./assets/img/logo.png";
import { Link } from "react-router-dom";
const MobileApp = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={3}>
          <ImgWrapper>
            <Img className="img-fluid" src={logo} alt="" />
          </ImgWrapper>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <RowWrapper>
            <MenuWrapper>
              <Menu>
                <Ul>
                  <Li>
                    <Link to={""}>Our Service</Link>
                  </Li>
                  <Li>
                    <Link to={""}>Legacy</Link>
                  </Li>
                  <Li>
                    <Link to={""}>Events</Link>
                  </Li>
                </Ul>
              </Menu>
            </MenuWrapper>
          </RowWrapper>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <RowWrapper>
            <MenuWrapper>
              <Menu>
                <Ul>
                  <Li>
                    <Link to={""}>Promotion</Link>
                  </Li>
                  <Li>
                    <Link to={""}>Our Branches</Link>
                  </Li>
                  <Li>
                    <Link to={""}>Our Team</Link>
                  </Li>
                </Ul>
              </Menu>
            </MenuWrapper>
          </RowWrapper>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <RowWrapper>
            <MenuWrapper>
              <Menu>
                <Ul>
                  <Li>
                    <Link to={""}>Contact Us</Link>
                  </Li>
                  <Li>
                    <Link to={""}>Downloads</Link>
                  </Li>
                  <Li>
                    <Link to={""}>rates</Link>
                  </Li>
                </Ul>
              </Menu>
            </MenuWrapper>
          </RowWrapper>
        </Col>
      </RowWrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={12}>
          {/* <MenuWrapper>
            <Menu>
              <Ul className="list-inline">
                <Li>
                  <Link to={""}>Promotion</Link>
                </Li>
                <Li>
                  <Link to={""}>Our Branches</Link>
                </Li>
                <Li>
                  <Link to={""}>Our Team</Link>
                </Li>
              </Ul>
            </Menu>
          </MenuWrapper> */}
          <p>PK © 2024. All Rights Reserved.   Privacy Policy, Terms and Conditions, FAQ</p>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default MobileApp;

const MenuWrapper = styled.div``;
const Menu = styled.div``;
const Ul = styled.div``;
const Li = styled.div`
  text-align: left;
  a {
    font-size: 15px;
    text-decoration: none;
    color: rgb(188, 189, 189);
    display: inline-block;
    font-weight: 400;
    letter-spacing: 0.2px;
    padding: 4px 0;
    margin: 0;
  }
`;
const IconBlock = styled.div`
  padding: 0 20px 0 0;
`;
const DivBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 15px 50px;
`;
const ALink = styled.a`
  color: #6d6d6d;
  font-size: 15px;
  font-weight: 500;
`;
const RowWrapper = styled(Row)`
  max-width: 90%;
  margin: 0 auto !important;
  width: 100%;
  p{
    color: #BCBDBD;
    text-align: center;
    font-size: 14px;
    padding: 50px 0 0 0;
  }
`;
const Wrapper = styled.div`
  background: #101016;
  text-align: center;
  padding: 70px 0 35px 0;
`;
const H1 = styled.h1`
  color: #212121;
  font-size: clamp(25px, 5vw, 45px);
  line-height: 64px;
  font-weight: 700;
  padding: 0 0 50px 0;
`;
const H1Colored = styled.h1`
  font-size: clamp(25px, 5vw, 25px);
  font-weight: 700;
  color: #212121;
  margin: 0;
  background: linear-gradient(123deg, #4bb4b1 16.69%, #e8905e 78.17%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
`;
const H6 = styled.h6`
  color: #454545;
  font-size: clamp(12px, 2vw, 16px);
  margin: 0 auto;
  padding: 10px 0 0 0;
  line-height: 24px;
  text-align: left;
`;

const ImgWrapper = styled.div`
  .app-icon {
    max-height: 45px;
  }
`;
const Img = styled.img`
  padding: 0;
  border-radius: 12px;
  max-height: 100px;
`;
