import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import mobile from "./assets/img/1.png";
import laptop from "./assets/img/2.png";
const Empower = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0 main">
        <Col xs={12} sm={12} md={6}>
          <RowWrapper className="h-100 align-items-center">
            <RowBlock>
              <H5>REMITTANCES</H5>
              <H1>Guaranteed Exchange Rates</H1>
              <H6>
                Aiming to deliver superior service quality for all clients at
                every consumer touch-point, Purshottam Kanji Exchange provides a
                wide range of money transfer services to meet all customer
                needs. By employing user-friendly mechanisms that promise speed,
                efficiency and real-time transparency, you can be sure that all
                your money remittance and foreign exchange requirements are
                handled efficiently by our strong service portfolio.
              </H6>
            </RowBlock>
          </RowWrapper>
        </Col>
        <Col xs={12} sm={12} md={6} className="p-0">
          <ImgWrapper>
            <Img className="img-fluid mobile" src={mobile} alt="" />
            <Img className="img-fluid" src={laptop} alt="" />
          </ImgWrapper>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default Empower;
const RowBlock = styled.div`
  max-width: 550px;
  margin: 0 5% 0 0;
`;
const RowWrapper = styled(Row)`
  margin: 0 auto !important;
  width: 100%;
  &.main {
    padding: 0 0 0 20%;
  }
`;
const Wrapper = styled.div`
  background: #15151c;
  text-align: center;
  padding: 60px 0;
`;
const H1 = styled.h1`
    color: #f1f1f1;
    font-size: clamp(25px, 5vw, 45px);
    line-height: 50px;
    font-weight: 700;
    text-align: left;
    margin: 0 0 15px 0;
`;
const H6 = styled.h6`
  color: #f1f1f1;
  font-size: clamp(12px, 2vw, 15px);
  margin: 0 auto;
  padding: 0 0 0 0;
  line-height: 30px;
  text-align: left;
`;
const H5 = styled.h5`
  color: #f9f9f9;
  font-size: clamp(12px, 2vw, 15px);
  margin: 0 auto;
  padding: 0 0 10px 0;
  line-height: 32px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;
`;
const ImgWrapper = styled.div`
  position: relative;
`;
const Img = styled.img`
  padding: 0;
  &.mobile {
    position: absolute;
    height: 90%;
    top: 5%;
    left: 0;
  }
`;
