import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import image1 from "./assets/img/1.png";
import image2 from "./assets/img/2.png";
import image3 from "./assets/img/3.png";
import image4 from "./assets/img/4.png";
import image5 from "./assets/img/5.png";
import image6 from "./assets/img/6.png";
import bankers1 from "./assets/img/bankers/1.png";
import bankers2 from "./assets/img/bankers/2.png";
import bankers3 from "./assets/img/bankers/3.png";
import bankers4 from "./assets/img/bankers/4.png";
import bankers5 from "./assets/img/bankers/5.png";
import android from "./assets/img/icon/1.png";
import apple from "./assets/img/icon/2.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const OneArray = [
  {
    id: 1,
    image: image1,
  },
  {
    id: 2,
    image: image2,
  },
  {
    id: 3,
    image: image3,
  },
  {
    id: 4,
    image: image4,
  },
  {
    id: 5,
    image: image5,
  },
  {
    id: 6,
    image: image6,
  },
];
const bankers = [
  {
    id: 1,
    image: bankers1,
  },
  {
    id: 2,
    image: bankers2,
  },
  {
    id: 3,
    image: bankers3,
  },
  {
    id: 4,
    image: bankers4,
  },
  {
    id: 5,
    image: bankers5,
  },
];
const Screens = () => {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={12}>
          <H6>PK EXCHANGE MOBILE APP</H6>
          <H1>Screens</H1>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <RowBlock className="m-0">
            {OneArray.map((item, id) => {
              return (
                <Col xs={12} sm={12} md={4}>
                  <CardCustom>
                    <Card.Body>
                      <ImgWrapper>
                        <Img className="img-fluid" src={item?.image} alt="" />
                      </ImgWrapper>
                    </Card.Body>
                  </CardCustom>
                </Col>
              );
            })}
          </RowBlock>
        </Col>
        <Col xs={12} sm={12} md={4}>
          <Row>
            <Col xs={12} sm={12} md={12} className="mb-3">
              <RowBlock className="m-0">
                <DivBlock>
                  <IconBlock>
                    <ImgWrapper>
                      <Img
                        className="img-fluid app-icon"
                        src={android}
                        alt=""
                      />
                    </ImgWrapper>
                  </IconBlock>
                  <TitleBlock>
                    <H1Colored>Google Play</H1Colored>
                    <ALink>Download Now</ALink>
                  </TitleBlock>
                </DivBlock>
              </RowBlock>
            </Col>
            <Col xs={12} sm={12} md={12} className="mt-3">
              <RowBlock className="m-0">
                <DivBlock>
                  <IconBlock>
                    <ImgWrapper>
                      <Img className="img-fluid app-icon" src={apple} alt="" />
                    </ImgWrapper>
                  </IconBlock>
                  <TitleBlock>
                    <H1Colored>App Store</H1Colored>
                    <ALink>Download Now</ALink>
                  </TitleBlock>
                </DivBlock>
              </RowBlock>
            </Col>
          </Row>
        </Col>
      </RowWrapper>
      <RowWrapper className="m-0 pt-5">
        <Col xs={12} sm={12} md={12}>
          <H6>PK EXCHANGE</H6>
          <H1>Our Main Bankers in Oman</H1>
        </Col>
        <Col xs={12} sm={12} md={12}>
          <RowBlock className="m-0">
            <Slider {...settings}>
              {bankers.map((item, id) => {
                return (
                  <Col xs={12} sm={12} md={3}>
                    <CardCustom>
                      <Card.Body>
                        <ImgWrapper>
                          <Img
                            className="img-fluid bankers-logo"
                            src={item?.image}
                            alt=""
                          />
                        </ImgWrapper>
                      </Card.Body>
                    </CardCustom>
                  </Col>
                );
              })}
            </Slider>
          </RowBlock>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default Screens;
const RowBlock = styled(Row)`
  border-radius: 12px;
  height: 100%;
  background-image: linear-gradient(
    to right bottom,
    #12121a,
    #101017,
    #0e0e14,
    #0b0b10,
    #08080c
  );
  border: solid 1px #0f0f14;
  padding: 30px;
`;
const CardCustom = styled(Card)`
  background: transparent !important;
  border: none !important;
`;
const RowWrapper = styled(Row)`
  max-width: 1350px;
  margin: 0 auto !important;
  width: 100%;
`;
const Wrapper = styled.div`
  text-align: center;
  padding: 90px 0;
  background-image: linear-gradient(
    to right top,
    #08080c,
    #08080c,
    #08080c,
    #07080c,
    #07080c
  );
  border-radius: 0;
  .card-body {
    padding: 15px 30px;
    .card-title {
      font-size: 18px;
      line-height: 28px;
      color: #fbfbfb;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #9b9b9b;
      font-weight: 500;
    }
  }
`;
const H1 = styled.h1`
  color: #e0e0e0;
  font-size: clamp(25px, 5vw, 35px);
  line-height: 40px;
  font-weight: 600;
  padding: 0 0 30px 0;
  text-align: left;
  max-width: 750px;
  letter-spacing: -1px;
`;
const H6 = styled.h6`
  color: #f9f9f9;
  font-size: clamp(12px, 2vw, 15px);
  margin: 0 auto;
  padding: 0 0 10px 0;
  line-height: 32px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;
`;
const ImgWrapper = styled.div``;
const Img = styled.img`
  filter: saturate(0.9);
  padding: 0;
  max-width: 100px;
  &.bankers-logo {
    max-width: 150px !important;
  }
`;
const DivBlock = styled.div``;
const ALink = styled.a`
  margin: 0 auto;
  padding: 10px 0 0 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  font-weight: 500;
`;
const IconBlock = styled.div`
  padding: 0;
  background: #111119;
  border: solid 1px #15151c;
  border-radius: 6px;
  width: 130px;
  height: 130px;
  margin: 0 auto 25px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
  img {
    filter: invert(0.6);
  }
`;
const TitleBlock = styled.div``;
const H1Colored = styled.h1`
  font-size: 18px;
  line-height: 28px;
  color: #eeeeee;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
