import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./assets/img/logo.png";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <MenuWrapper>
      <Wrapper>
        <DivBlock>
          <Logo>
            <ImgWrapper>
              <Link to={"/"}>
                <Img className="img-fluid" src={logo} alt="" />
              </Link>
            </ImgWrapper>
          </Logo>
        </DivBlock>
        <MenuBlock>
          <UlWrapper className="list-inline">
            <LiWrapper className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </LiWrapper>
            <LiWrapper className="list-inline-item">
              <Link to={"/home"}>What We do</Link>
            </LiWrapper>
            <LiWrapper className="list-inline-item">
              <Link to={"/aboutUs"}>About Us</Link>
            </LiWrapper>
            <LiWrapper className="list-inline-item">
              <Link to={"/contactUs"}>Contact us</Link>
            </LiWrapper>
            <LiWrapper className="list-inline-item">
              <Link to={"/apps"}>Apps</Link>
            </LiWrapper>
          </UlWrapper>
          <ButtonWrapper>Register Now</ButtonWrapper>
        </MenuBlock>
      </Wrapper>
    </MenuWrapper>
  );
};
export default Menu;

const DivBlock = styled.div`
  display: flex;
`;
const ButtonWrapper = styled.button`
  background-color: #ffc012;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid #ffc012;
  font-size: 13px;
  padding: 10px 20px;
  color: #ffffff;
`;
const UlWrapper = styled.ul`
  margin: 0;
  padding: 0 10% 0 0;
`;
const LiWrapper = styled.li`
  &:nth-last-child(1) {
    a {
      padding: 20px 0 20px 30px;
    }
  }
  a {
    display: flex;
    padding: 5px 30px;
    color: #fafafa;
    font-size: clamp(12px, 2vw, 15px);
    text-decoration: none;
    font-weight: 500;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Logo = styled.div`
  max-width: 215px;
  display: flex;
  align-items: center;
`;
const MenuBlock = styled.div`
  padding: 0 0 0 30px;
  width: calc(100% - 210px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const MenuWrapper = styled.div`
  background: rgb(16 16 16 / 50%);
  padding: 5px 12%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: auto;
  display: flex;
  align-items: center;
  z-index: 99;
  backdrop-filter: blur(10px);
`;
const ImgWrapper = styled.div``;
const Img = styled.img``;
