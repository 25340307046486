import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import bg from "./assets/bg.png";
const stepsArray = [
  {
    id: 1,
    name: "Log In",
    description: "Login using username and password combination",
    step: "Step 1",
  },
  {
    id: 2,
    name: "Menu Selection",
    description: "Select appropriate Menu (Bank Transfer or Fast Cash)",
    step: "Step 2",
  },
  {
    id: 3,
    name: "Beneficiary Selection",
    description: "Select appropriate Menu (Bank Transfer or Fast Cash)",
    step: "Step 3",
  },
  {
    id: 4,
    name: "Amount & Purpose of Remittance",
    description: "Select appropriate Menu (Bank Transfer or Fast Cash)",
    step: "Step 4",
  },
  {
    id: 5,
    name: "Get Transaction Rate & Charges",
    description: "Get rate and transaction charges for transaction",
    step: "Step 5",
  },
  {
    id: 6,
    name: "OTP Verification",
    description:
      "Authorised your transaction with your desired mode of authentication",
    step: "Step 6",
  },
  {
    id: 7,
    name: "Final Authentication",
    description: "Transaction is processed by AIM",
    step: "Step 7",
  },
];
const Steps = () => {
  return (
    <Wrapper>
      <MainH6>
        Steps to send money in <Name>PK Exchange</Name>
      </MainH6>
      <ColBlock>
        <WrapperRow className="m-0">
          {stepsArray.map((item, index) => {
            return (
              <RowWrapper>
                <Col xs={12} sm={12} md={6}>
                  <Block className="Block">
                    <BlockSub className="BlockSub">
                      <H1>{item?.name}</H1>
                      <H6>{item?.description}</H6>
                    </BlockSub>
                  </Block>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <StepBlock className="StepBlock">
                    <Step className="Step">{item?.step}</Step>
                  </StepBlock>
                </Col>
              </RowWrapper>
            );
          })}
        </WrapperRow>
      </ColBlock>
    </Wrapper>
  );
};
export default Steps;
const StepBlock = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  padding: 0 25px;
`;
const RowWrapper = styled(Row)`
  width: 100%;
  max-width: 650px;
  position: relative;
  &::before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffc012;
    content: "";
    min-width: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border: solid 1.5px #000000;
    box-shadow: 0px 0px 0px 1px #ffc012;
    top: calc(50% - 10px);
  }
  .Block {
    position: relative;
    &::before {
      content: "";
      top: calc(50% - 15px);
      width: 0;
      height: 0;
      clear: both;
      position: absolute;
    }
  }
  &:nth-child(odd) {
    flex-direction: row-reverse;
    position: relative;
    margin: 0;
    .StepBlock {
      justify-content: flex-end;
    }
    .BlockSub {
      text-align: left;
    }
    .Block {
      &::before {
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 20px solid #202020;
        left: -35px;
      }
    }
  }
  &:nth-child(even) {
    .StepBlock {
      justify-content: flex-start;
    }
    .BlockSub {
      text-align: right;
    }
    .Block {
      &::before {
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 20px solid #202020;
        right: -35px;
      }
    }
  }
  /* &:nth-child(odd) {
    flex-direction: row-reverse;
    .StepBlock,
    .Block {
      justify-content: flex-end !important;
    }
  } */
`;
const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;
const Step = styled.div`
  color: #535353;
  font-weight: 700;
  font-size: 18px;
`;
const ColBlock = styled.div`
  position: relative;
  &::before {
    content: "";
    top: calc(50% - 0px);
    width: 2px;
    height: 100%;
    background: #ffc012;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
`;
const BlockSub = styled.div``;
const ColCustom = styled(Col)`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
`;
const Block = styled.div`
  background: #202020;
  border-radius: 85px;
  padding: 5px 15px;
  border: solid 20px #202020;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  position: relative;
  margin: 0 25px;
`;
const Name = styled.div`
  color: #ffc012;
  display: inline-block;
  padding: 0 0 0 2px;
  text-align: left;
  font-weight: 600;
`;
const Wrapper = styled.div`
  text-align: center;
  padding: 80px 40px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  .card-body {
    padding: 30px;
    .card-title {
      font-size: 20px;
      line-height: 28px;
      text-align: left;
      color: #212121;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
  }
`;
const H1 = styled.h1`
  color: #ffffff;
  font-size: clamp(13px, 3vw, 16px);
  padding: 0;
`;

const MainH6 = styled.h6`
  color: #ffffff;
  font-size: clamp(12px, 2vw, 20px);
  margin: 0 auto;
  padding: 15px 10px 80px 10px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
`;
const H6 = styled.h6`
  color: #bcbcbc;
  font-size: clamp(12px, 2vw, 13px);
  margin: 0 auto;
  padding: 0;
  line-height: 20px;
  font-weight: 300;
`;
