import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bannerImage from "./assets/img/banner.jpg";

const Banner = () => {
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={6}>
          <Row className="m-0 align-items-center h-100">
            <BannerText>
              <BannerH1 data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="500">Oman's First Exchange</BannerH1>
              <BannerH6 data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                Send Money<br></br>
                Online Safe, Simple & Fast
              </BannerH6>
              <BannerButton data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="700">Send Money Now</BannerButton>
            </BannerText>
          </Row>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default Banner;

const BannerButton = styled.button`
  background-color: #ffc012;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid #ffc012;
  font-size: 14px;
  padding: 12px 20px;
  color: #ffffff;
  margin: 10px 0 0 0;
`;
const RowWrapper = styled(Row)`
  max-width: 80%;
  margin: 0 auto !important;
  height: 100vh;
  align-items: center;
`;
const BannerH1 = styled.h1`
  color: #ffffff;
  font-size: clamp(25px, 5vw, 75px);
  font-weight: 700;
`;
const BannerH6 = styled.h6`
  color: #bcbdbd;
  font-size: clamp(12px, 2vw, 20px);
  line-height: 32px;
`;
const BannerText = styled.div`
  max-width: 500px;
`;
const Wrapper = styled.div`
  background-image: url(${bannerImage});
  min-height: 100vh;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: #101010;
  margin: 0;
  padding: 60px 0 0 0;
`;
