import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import image1 from "./assets/img/carousal/1.png";
import image2 from "./assets/img/carousal/2.png";
import image3 from "./assets/img/carousal/3.png";
import image4 from "./assets/img/carousal/4.png";
import image5 from "./assets/img/carousal/5.png";
import image6 from "./assets/img/carousal/6.png";
import image7 from "./assets/img/carousal/7.png";
import image8 from "./assets/img/carousal/8.png";
import image9 from "./assets/img/carousal/9.png";
import image10 from "./assets/img/carousal/10.png";
import image11 from "./assets/img/carousal/11.png";
import image12 from "./assets/img/carousal/12.png";
import image13 from "./assets/img/carousal/13.png";
import image14 from "./assets/img/carousal/14.png";
import image15 from "./assets/img/carousal/15.png";
import image16 from "./assets/img/carousal/16.png";
import image17 from "./assets/img/carousal/17.png";
import image18 from "./assets/img/carousal/18.png";
const Remittances = () => {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const carousalArray = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image2,
    },
    {
      id: 3,
      image: image3,
    },
    {
      id: 4,
      image: image4,
    },
    {
      id: 5,
      image: image5,
    },
    {
      id: 6,
      image: image6,
    },
    {
      id: 7,
      image: image7,
    },
    {
      id: 8,
      image: image8,
    },
    {
      id: 9,
      image: image9,
    },
    {
      id: 10,
      image: image10,
    },
    {
      id: 11,
      image: image11,
    },
    {
      id: 12,
      image: image12,
    },
    {
      id: 13,
      image: image13,
    },
    {
      id: 14,
      image: image14,
    },
    {
      id: 15,
      image: image15,
    },
    {
      id: 16,
      image: image16,
    },
    {
      id: 17,
      image: image17,
    },
    {
      id: 18,
      image: image18,
    }
  ];
  return (
    <Wrapper>
      <RowWrapper className="m-0">
        <Col xs={12} sm={12} md={6}>
          <LeftBlock>
            <H1>Remittances</H1>
            <H6>
              We provide remittance services such as Bank account transfers,
              Mobile/Wallet Payments Cash Pickups, Door to Door delivery.
              Instant access to all geographies along with an extensive network
              of correspondent banks helps you to conduct remittances.
              Additionally, our strong team of Customer Relationship Executives
              assists and supports your remittance needs – with 100% service
              satisfaction.
            </H6>
            <H2>Corporate Payments:</H2>
            <H5>
              We provide most efficient, safe and secure payment mode to route
              your funds across the globe. We are committed providing excellent
              customer services, personal attention, and superior customer
              experiences. We provide corporate remittances to below listed
              countries:
            </H5>

            <Slider {...settings}>
              {carousalArray.map((item, index) => {
                return (
                  <ImgWrapper>
                    <ImgWrapperSub>
                      <Img className="img-fluid" src={item?.image} alt="" />
                    </ImgWrapperSub>
                  </ImgWrapper>
                );
              })}
            </Slider>
          </LeftBlock>
        </Col>
        <Col xs={12} sm={12} md={6} className="pe-0">
          <TabWrapper>
            <Tabs
              defaultActiveKey="india"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="india" title="India">
                <TabH5>Electronic Transfer of funds to Bank Account.</TabH5>
                <TabUl>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any Bank in India.
                  </TabLi>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any Banks in India.
                  </TabLi>
                  <TabLi>
                    We ensure credits to the respective accounts within 1 min to
                    24 hours of remittances.
                  </TabLi>
                  <TabLi>
                    Electronic Transfer of funds facility is available at all
                    our branches and online platforms.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
              <Tab eventKey="bangladesh" title="Bangladesh">
                <TabH5>Electronic Transfer of funds to Bank Account.</TabH5>
                <TabUl>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any banks in Bangladesh through a number of banking and
                    non banking arrangements.
                  </TabLi>
                  <TabLi>
                    PKE has arrangements with various banks in Bangladesh for
                    sending money to any bank accounts in Bangladesh.
                  </TabLi>
                  <TabLi>
                    Bank accounts will be credited within 1 hour to 2 working
                    days depending on the location of the branch where the
                    amount is to be credited.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
              <Tab eventKey="sriLanka" title="Sri Lanka">
                <TabH5>Transfer to Bank Account.</TabH5>
                <TabUl>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any banks in Sri Lanka through several banking and
                    non-banking arrangements.
                  </TabLi>
                  <TabLi>
                    Bank accounts will be credited within 1 min to 2 working
                    days depending on the beneficiary banks core banking system.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
              <Tab eventKey="philippines" title="Philippines">
                <TabH5>Electronic Transfer of funds to Bank Account.</TabH5>
                <TabUl>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any banks in Philippines through several banking and
                    non-banking arrangements.
                  </TabLi>
                  <TabLi>
                    Bank accounts will be credited within 1 min to 2 working
                    days depending on the beneficiary banks core banking system.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
              <Tab eventKey="nepal" title="Nepal">
                <TabH5>Transfer to Bank Account.</TabH5>
                <TabUl>
                  <TabLi>
                    We offer electronic fund transfer to accounts maintained
                    with any banks in Philippines through several banking and
                    non-banking arrangements.
                  </TabLi>
                  <TabLi>
                    Bank accounts will be credited within 1 min to 2 working
                    days depending on the beneficiary banks core banking system.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
              <Tab eventKey="otherCountries" title="Other Countries">
                <TabH5>Telegraphic Transfer</TabH5>
                <TabUl>
                  <TabLi>
                    Foreign Currency denominated Telegraphic Transfers can be
                    made through PK to other countries like US, UK, EUROPE,
                    CANADA, AUSTRALIA, NEW ZEALAND, SAUDI ARABIA, UAE etc for
                    credit to beneficiary accounts with their banks.
                  </TabLi>
                </TabUl>
                <TabP>
                  Requirements: ID proof of remitter, name &amp; bank account
                  number of beneficiary and branch name.
                </TabP>
              </Tab>
            </Tabs>
          </TabWrapper>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
};
export default Remittances;

const ImgWrapperSub = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const LeftBlock = styled.div`
  padding: 0 15% 0 0;
`;
const TabH5 = styled.h5`
  text-align: left;
  padding: 0 0 5px 0;
  font-size: 15px;
  color: #9e9e9e;
`;
const TabUl = styled.ul`
  padding: 5px 0 0 25px;
  list-style: circle;
  margin: 0;
  text-align: left;
  font-size: 15px;
`;
const TabLi = styled.li`
  padding: 0 0 10px 0;
  color: #757575;
  font-size: 13px;
`;
const TabP = styled.p`
  text-align: left;
  font-size: 14px;
  padding: 0 0 0 5px;
  font-style: italic;
  color: #757575;
  margin: 0;
  font-weight: 500;
`;
const TabWrapper = styled.div`
  background: #101017;
  border: solid 5px #14141c;
  border-radius: 10px 0 0 10px;
  padding: 50px 50px;
  height: 100%;
  .tab-content {
    padding: 10px 0 0 0;
  }
  .nav-tabs {
    border-bottom-color: transparent;
    justify-content: flex-start;
    margin: 0;
    .nav-item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      white-space: nowrap;
      margin: 0 0 15px 0;
      &:hover {
        .nav-link {
          background: transparent !important;
        }
      }
      .nav-link {
        color: #6d6d6d !important;
        background: none;
        border: none !important;
        margin: 0 20px 5px 5px;
        border-radius: 12px;
        padding: 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          background: #101017;
          width: 100%;
          left: 0;
          right: 0;
          bottom: -5px;
        }
        &.active {
          &::after {
            background: #ffc012;
          }
        }
      }
    }
  }
`;
const RowWrapper = styled(Row)`
  margin: 0 auto !important;
  width: 100%;
  padding: 0 0 0 20%;
`;
const Wrapper = styled.div`
  background: #000000;
  text-align: center;
  padding: 120px 0;
`;
const H1 = styled.h1`
  color: #e0e0e0;
  font-size: clamp(25px, 5vw, 35px);
  line-height: 40px;
  font-weight: 500;
  padding: 0 0 30px 0;
  text-align: left;
  max-width: 750px;
  letter-spacing: -1px;
`;
const H6 = styled.h6`
  color: #9e9e9e;
  font-size: clamp(12px, 2vw, 15px);
  margin: 0 auto;
  padding: 0 0 0 0;
  line-height: 30px;
  text-align: left;
`;
const H2 = styled.h2`
  color: #e0e0e0;
  font-size: clamp(22px, 5vw, 20px);
  line-height: 40px;
  font-weight: 500;
  padding: 25px 0 0 0;
  text-align: left;
  letter-spacing: -1px;
`;
const H5 = styled.h5`
  color: #9e9e9e;
  font-size: clamp(12px, 2vw, 14px);
  margin: 0 auto;
  padding: 0 0 15px 0;
  line-height: 25px;
  text-align: left;
  font-weight: 300;
`;
const ImgWrapper = styled.div`
  border-radius: 6px;
  height: 130px;
  margin: 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
`;
const Img = styled.img`
  filter: saturate(0.9);
  padding: 0 15px 0 0;
`;
